import { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import { ProjectDatatype } from './types';
import { backendAPI } from './services';
import config from './config';
import QueryString from 'query-string';
import { useLocation } from 'react-router-dom';

interface Props {
    datatype: ProjectDatatype;
    slug: string;
    version: string | null;
}

const DATATYPE_URL_TO_NAME: Record<string, ProjectDatatype> = {
    "panorama": "PANORAMA",
    "realitycapture": "REALITY_CAPTURE",
    "indoorrealitycapture": "INDOOR_REALITY_CAPTURE",
    "gallery": "GALLERY",
    "galleries": "GALLERY",
    "video": "VIDEO",
    "map": "MAP",
}

const isDev = (process.env.NODE_ENV == "development") ? true : false

const Project = (props: Props) => {

    const [path, setPath] = useState<string | null>(null);
    const [notFound, setNotFound] = useState<string | null>(null);
    const [base, setBase] = useState<string | null>(null);
    const location = useLocation();
    const queryString = QueryString.parse(location.search);

    useEffect(() => {
        
        const fetchProject = async () => {
            // console.log(`Get Project ${DATATYPE_URL_TO_NAME[props.datatype.toLocaleLowerCase()]}`)
            const project = await backendAPI.getProjectByDatatypeSlug(DATATYPE_URL_TO_NAME[props.datatype.toLocaleLowerCase()], encodeURIComponent(props.slug));
            // const project = await backendAPI.getProjectBySlug(encodeURIComponent(props.slug));
            if (!project) {
                // TODO: 404 page
                setNotFound(`Project ${props.slug} not found`);
                return;
            }

            if (props.datatype.toLowerCase() == "gallery" || props.datatype.toLowerCase() == "galleries") {
                // console.log("Setting Gallery Path")
                setPath (
                    `${props.slug}`
                )
                setBase (
                    `https://old-domain.taylorsds.com.au/galleries`
                )
                return;
            }

            const versionId = props.version || project.currentVersionId;
            if (!versionId) {
                // TODO: 404 page
                setNotFound(`Project ${props.slug} is not active`);
                return;
            }

            const projectVersion = await backendAPI.getProjectVersion(project.projectId, versionId);
            if (!projectVersion) {
                // TODO: 404 page
                setNotFound(`Version ${props.version} not found`);
                return;
            }
            setBase(`${config.PROJECT_BASE}`)
            setPath(
                `${projectVersion.rootPath}/${projectVersion.indexFile || 'index.html'}${queryString.startscene? "?startscene=" + queryString.startscene : ""}`.replace(/\/{2,}/g, '/')
            );
                        
        };

        

        if (props.datatype && props.slug) {
            fetchProject();
        }
    }, [props.datatype, props.slug, props.version]);
    if (!path) {
        return notFound ? <div>{notFound}</div> : <div>Loading...</div>;
    }

    console.table(props)
    return (
            <Iframe
                width="100%"
                height="100%"
                frameBorder={0}
                url={`${base}/${path}`}
                allowFullScreen
            />
        );
};

export default Project;
