import axios, { AxiosInstance, Method } from "axios";

class API {
  private instance: AxiosInstance;

  constructor(baseURL: string) {
    if (!baseURL) {
      throw new Error("baseURL required");
    }

    this.instance = axios.create({
      baseURL,
    });
  }

  protected async req(method: Method, endPoint: string, body = {}) {
    // @ts-ignore
    return await this.instance[method](`/${endPoint}`, body);
  }

  protected async post(endPoint: string, body?: any) {
    return this.req("post", endPoint, body);
  }

  protected async put(endPoint: string, body?: any) {
    return this.req("put", endPoint, body);
  }

  protected async patch(endPoint: string, body: any) {
    return this.req("patch", endPoint, body);
  }

  protected async get(endPoint: string, body?: any) {
    return this.req("get", endPoint, { params: body });
  }

  protected async delete(endPoint: string, body?: any) {
    return this.req("delete", endPoint, { params: body });
  }
}

export default API;
