import API from './api';
import { Project, ProjectVersion } from '../types';

export default class BackendAPI extends API {
  constructor(apiURL: string) {
    super(`${apiURL}/`);
  }

  public getProjectBySlug = async (slug: string): Promise<Project | null> => {
    const res = await this.get(`projects/slug/${slug}`);
    if (res.data.project?.projectStatus === "DELETED") { 
      return null;
    } 
    return res.data.project;
  };

  public getProjectByDatatypeSlug = async (datatype: string, slug: string): Promise<Project| null> => {
    const res = await this.get(`projects/datatype/${datatype}/slug/${slug}`);
    if (res.data.project?.projectStatus === "DELETED") { 
      return null;
    } 
    return res.data.project;
  }

  public getProjectVersion = async (projectId: string, versionId: string): Promise<ProjectVersion> => {
    const res = await this.get(`projects/${projectId}/versions/${versionId}`);
    return res.data;
  };
}
