import './App.css';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import Project from './Project';
import { ProjectDatatype } from './types';
import { RouteComponentProps } from 'react-router';
import { pathToRegexp, match, parse } from 'path-to-regexp';
import queryString from 'querystring';
import TaylorsGallery from './pages/gallery';


function App() {
  return (
    <Router>
      <Switch>

        <Route exact path="/demo/gallery" component={() => {
          return <TaylorsGallery />
        }}/>

        <Route path="/:datatype/:slug(.+)/:version(\d+)" component={({ match }: RouteComponentProps<{
          datatype: ProjectDatatype;
          slug: string;
          version: string;
          file?: string;
        }>) => {
          console.log(`Datatype: ${match.params.datatype}, Slug: ${match.params.slug}, Version: ${match.params.version}`)
          
          return <Project
              datatype={match.params.datatype}
              slug={match.params.slug}
              version={match.params.version}
          />
        }} />

        <Route path="/:datatype/:slug(.+)/App" component={({ match }: RouteComponentProps<{
          datatype: ProjectDatatype;
          slug: string;
          file?: string;
        }>) => {
          console.log(`Datatype: ${match.params.datatype}, Slug: ${match.params.slug} with /App`)
          
          return <Project
              datatype={match.params.datatype}
              slug={match.params.slug}
              version={null}
          />
        }} />        
        
        <Route path="/:datatype/:slug(.+)\/(.*\.html?)" component={({ match }: RouteComponentProps<{
          datatype: ProjectDatatype;
          slug: string;
          file?: string;
        }>) => {
          console.log(`Datatype: ${match.params.datatype}, Slug: ${match.params.slug} with *.html`)
          
          return <Project
              datatype={match.params.datatype}
              slug={match.params.slug}
              version={null}
          />
        }} />

        <Route path="/:datatype/:slug(.+)//" component={({ match }: RouteComponentProps<{
          datatype: ProjectDatatype;
          slug: string;
          file?: string;
        }>) => {
          console.log(`Datatype: ${match.params.datatype}, Slug: ${match.params.slug} with /`)
          
          return <Project
              datatype={match.params.datatype}
              slug={match.params.slug}
              version={null}
          />
        }} />

        <Route path="/:datatype/:slug(.+)" component={({ match }: RouteComponentProps<{
          datatype: ProjectDatatype;
          slug: string;
          file?: string;
        }>) => {
          console.log(`Datatype: ${match.params.datatype}, Slug: ${match.params.slug} without /`)
          
          return <Project
              datatype={match.params.datatype}
              slug={match.params.slug}
              version={null}
          />
        }} />

        <Route exact path="/" component={() => {
          window.location.href = 'https://taylorsds.com.au/';
          return null;
        }}>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
