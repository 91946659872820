import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const baseDir = "https://d3heti2825fxdw.cloudfront.net/galleries/seek/"
const fullDir = "gallery-images/"
const thumbDir = "resources/cache/"
const galleryItems = [
    {
      original: '21397_SEEK_Building_01.jpg',
      thumbnail: '300x200-75-0f1460e42a4e8c8694e3b6106f505633728bcde7e09fd351b4cf88764408f6ab.jpg',
      thumbnailLabel: "01",
      description: "The first image",
      originalTitle: "New Title"
    },
    {
        original: '21397_SEEK_Building_02.jpg',
        thumbnail: '300x200-75-1d253dad4cbaa6560126fec91a6fa96fc37aa9614aac09b9fc98b633b04d0175.jpg',
        description: "The one after that",
        thumbnailLabel: "02",
    },
    {
      original: '21397_SEEK_Building_03.jpg',
      thumbnail: '300x200-75-efd6c55e681466fca7d2584153fcd8559301f271d28acf50d716ebbd19199a7f.jpg',
      description: "A totally different one",
      thumbnailLabel: "03",
    },
    {
      original: '21397_SEEK_Building_04.jpg',
      thumbnail: '300x200-75-dd9d15b9d445687fea58429897145a6e58937855de6797219f1f35245a3601ad.jpg',
      description: "Woohoo, winner",
      thumbnailLabel: "04",
    },
  ];

const gallery: ReactImageGalleryItem[] | {
    original: string; thumbnail: string; originalTitle: string;
}[] = []
galleryItems.map((galleryItem) => {
    gallery.push({
        original: baseDir + fullDir + galleryItem.original,
        thumbnail: baseDir + thumbDir + galleryItem.thumbnail,
        originalTitle: (galleryItem.originalTitle) ? galleryItem.originalTitle : "Title",
        originalAlt: "Seek Image",
        thumbnailLabel: galleryItem.thumbnailLabel,
        description: `Description: ${galleryItem.description}`
    })
})

const TaylorsGallery = () => {



    return <ImageGallery items={gallery} showPlayButton={false}/>

}
export default TaylorsGallery