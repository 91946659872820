interface Config {
  BACKEND_API_BASE: string;
  PROJECT_BASE: string;
}

const testing: Config = {
  BACKEND_API_BASE: "http://backend.com",
  PROJECT_BASE: "https://d3heti2825fxdw.cloudfront.net",
};

const development: Config = {
  BACKEND_API_BASE:
      "https://zlnwp81mbg.execute-api.ap-southeast-2.amazonaws.com/prod/",
  PROJECT_BASE: "https://d3heti2825fxdw.cloudfront.net",
};

const production: Config = {
  BACKEND_API_BASE:
    "https://zlnwp81mbg.execute-api.ap-southeast-2.amazonaws.com/prod/",
  PROJECT_BASE: "https://d3heti2825fxdw.cloudfront.net",
};

const configMap: Record<string, Config> = {
  test: testing,
  development,
  production,
};

export default configMap[process.env.NODE_ENV];
